import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import ArticleTeaserSection from '../components/content/ArticleTeaserSection';
import ArticleTeaser from '../components/content/ArticleTeaser';
import TextSection from '../components/content/TextSection';

import titleImage from '../assets/img/praxis/titleImage.jpg'
import bewegungsraumImage from '../assets/img/praxis/bewegungsraum.jpg'
import werkstattImage from '../assets/img/praxis/werkstatt.jpg'
import kuecheImage from '../assets/img/praxis/kueche.jpg'
import behandlungsraumImage from '../assets/img/praxis/behandlungsraum.jpg'
import warteraumImage from '../assets/img/praxis/warteraum.jpg'
import {Helmet} from "react-helmet";

const Praxis: React.FC = (props) => {
    return (
        <Page>
              <Helmet>
                <title>Praxis</title>
              </Helmet>
              
              <TextSection>
                  <h1>Die Praxis</h1>
                    <p>
                    Im Januar 2000 gründete Uta Bartel die erste ergotherapeutische Praxis in Mettmann. 
                    Im Januar 2022 stieg Julia Söffing-Bartel mit in die Praxisleitung ein. 
                    Nach drei Jahren als Gemeinschaftspraxis geht Uta Bartel 2025 in den wohlverdienten Ruhestand. 
                    Julia Söffing-Bartel führt die Praxis nun als <strong>„Praxis für Ergotherapie – Julia Söffing-Bartel“</strong> fort. 
                    Im Laufe der Jahre bildete sich ein eingespieltes Team von Therapeutinnen. 
                    Jede von ihnen hat verschiedene Zusatzqualifikationen, durch die sich ein breites Angebot ergibt.
                    </p>

                    <p>
                    Regelmäßige Weiterbildungen sind selbstverständlich. 
                    Es ist uns wichtig, Sie einfühlsam und ganzheitlich zu behandeln. 
                    Deshalb nehmen wir uns viel Zeit, die Ursachen Ihrer Schwierigkeiten zu ergründen, Ihre Ressourcen aufzuspüren und zu nutzen.
                    </p>

                    <p>
                    Die Praxis liegt zentral am Jubiläumsplatz 2 mitten in der Mettmanner Innenstadt. 
                    Unsere Räume befinden sich im 2. Stock, das Haus ist mit einem Aufzug ausgestattet. 
                    Direkt vor dem Haus befinden sich Bushaltestellen und auch die Regiobahn ist fußläufig in 5 Minuten erreichbar. 
                    Zum Parken stehen die Parkhäuser in direkter Nähe (Neandertalpassage/Rewe, Sparkasse), sowie öffentliche Parkplätze an der Johannis-Flintrop-Straße, zur Verfügung.
                    </p>

                    <p>
                    Die neuen Praxisräume bieten eine helle, freundliche und angenehme Atmosphäre. 
                    Vom Bewegungsraum und Behandlungsraum über die kreative Werkstatt bis hin zu einer kleinen Küche ist alles vorhanden, um eine individuell zugeschnittene Therapie durchführen zu können.
                    </p>
              </TextSection>
              <ArticleTeaserSection title="Unsere Räumlichkeiten">
                <ArticleTeaser  
                  titleImage={bewegungsraumImage}
                       title={"Therapieraum"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={werkstattImage}
                       title={"Therapie-/Werkraum"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={kuecheImage}
                       title={"Therapieraum/Alltagstraining"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={behandlungsraumImage}
                       title={"Therapieraum"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={warteraumImage}
                       title={"Warteraum"}>
                </ArticleTeaser>
              </ArticleTeaserSection>
          </Page>
    )
}

export default Praxis
