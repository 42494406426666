import React from 'react'
import Page from '../components/Page';
import ArticleTeaserSection from '../components/content/ArticleTeaserSection';
import Portrait from '../components/content/Portrait';
import utaImage from '../assets/img/team/ubartel.jpg'
import lisaImage from '../assets/img/team/lmhansen.jpg'
import charlotteImage from '../assets/img/team/cborgers.jpg'
import simoneImage from '../assets/img/team/ssoelling.jpg'
import juliaImage from '../assets/img/team/jbartel.jpg'
import christinaImage from '../assets/img/team/corselli.jpg'
import irisImage from '../assets/img/team/iris.jpg'
import nicoleImage from '../assets/img/team/nicole.jpg'
import {Helmet} from "react-helmet";


const Team: React.FC = (props) => {
    return (
        <Page>
              <Helmet>
                <title>Team</title>
              </Helmet>
              <ArticleTeaserSection title="Unser Team">
                <div className="row justify-content-center px-0 mx-0"> 
                <Portrait  
                  titleImage={juliaImage}
                       title={"Julia Söffing-Bartel"}>
                </Portrait>
                </div>
                <Portrait  
                  titleImage={lisaImage}
                       title={"Lisa-Marie Schmidt"}>
                </Portrait>
                <Portrait  
                  titleImage={simoneImage}
                       title={"Simone Sölling"}>
                </Portrait>
                <Portrait  
                  titleImage={charlotteImage}
                       title={"Charlotte Borgers"}>
                </Portrait>
                
                <Portrait  
                  titleImage={irisImage}
                       title={"Iris Tüssner-Kicinski"}>
                </Portrait>
                <Portrait  
                  titleImage={utaImage}
                       title={"Uta Bartel"}>
                </Portrait>
                
                <Portrait  
                  titleImage={nicoleImage}
                       title={"Nicole Maurer (in Elternzeit)"}>
                </Portrait>
                
                
                
              </ArticleTeaserSection>
        </Page>
    )
}

export default Team