import React from 'react'
import Page from '../components/Page';
import TextSection from '../components/content/TextSection';
import {Helmet} from "react-helmet";

const Impressum: React.FC = (props) => {
    return (
        <Page id={"pageImprint"}>
            <Helmet>
                <title>Impressum</title>
            </Helmet>
            <TextSection>
            <h1>Impressum</h1>
            <h3>Anschrift</h3>

            <p>
                Praxis für Ergotherapie - Julia Söffing-Bartel<br />
                Jubiläumsplatz 2<br />
                40822 Mettmann
            </p>
            <p>
                <strong>E-Mail:</strong> <a href="mailto:info@ergotherapie-mettmann.de">info@ergotherapie-mettmann.de</a><br />
                <strong>Telefon:</strong> <a href="tel://00492104286280">02104-286280</a>
            </p>

            <h3>Berufsbezeichnungen:</h3>

            <h4>Berufsbezeichung Julia Söffing-Bartel:</h4>
            <p>
                staatlich anerkannte Ergotherapeutin - seit 15.10.2005 durch die staatlich anerkannte Schule für Ergotherapie in Essen.
            </p>

            <h3>Zulassung und Institutionskennzeichnung</h3>
            <p>
                <strong>Zulassung:</strong> seit 01.01.2000/01.01.2022 aufgrund §124 SGB V <br/>
                <strong>Institutionskennzeichnung:</strong> 480511304
            </p>

            <h3>Inhaber</h3>
            <p>Julia Söffing-Bartel (Betreiber der Webseite)</p>

            <h3>Haftungshinweis</h3>
            <p>
                Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. 
                Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </p>

            <h3>Streitigkeiten</h3>
            <p>
                Hier erhalten Sie als Verbraucher die Möglichkeit, sich bei Streitigkeiten mit uns an eine Schlichtungsstelle zu wenden: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>
            </p>
            </TextSection>
          </Page>
    )
}

export default Impressum